import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Feed from '../social/components/feed/Feed'
import KineContext from '../../context/KineContext'
import { getSocialProfileByAddres, followAsync, unfollowAsync, getPostsById } from '../../service/social/socialService'
import './style.scss'
import { Loading } from '../../components/loading'
import MyFestivals from './Festivals'
import ModalFollowersAndFollowing from './modal'
import Tabs from 'src/components/_Dsc/Tabs'
import Button from 'src/components/_Dsc/Button'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import UserFiles from './Documents'

interface Props {
	address?: string
	isEdit?: any
}

const SocialView: React.FC<Props> = () => {
	const [fetch, setFetch] = useState<Boolean>(false)
	const [currentSelectedTab, setCurrentSelectedTab] = useState<string>('posts')
	const [posts, setPosts] = useState<any>([])
	const [isFollow, setIsFollow] = useState<boolean>(false)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [reqData, setReqData] = useState<any>(null)
	const [currentTab, setCurrentTab] = useState('')
	const { user, color, profile, handleSetGlobalFetch, setGlobalToast } = useContext(KineContext)

	const navigate = useNavigate()

	const handleClick = async (unfollow: boolean) => {
		if (!user) {
			return alert('Please login first')
		}
		if (unfollow) {
			let req = await unfollowAsync(reqData.user, user.address)
			if (req) {
				setIsFollow(false)
				reqData.followers -= 1
				handleSetGlobalFetch()
				setReqData(reqData)
			}
			return true
		} else {
			setIsFollow(true)
			reqData.followers += 1
			await followAsync(reqData.user, user.address)
			handleSetGlobalFetch()
			setReqData(reqData)
			return true
		}
	}

	const getUserDataReq = async () => {
		setFetch(false)
		setGlobalToast(true)
		let req = await getSocialProfileByAddres(window.location.pathname.toString().split('/')[3])

		if (req) {
			if (req.isfollow) {
				setIsFollow(true)
			}
			setReqData(req)

			const getPosts: any = await getPostsById(window.location.pathname.toString().split('/')[3])
			if (getPosts && getPosts.data) {
				setPosts(getPosts.data)
			}
		}

		setFetch(true)
		setGlobalToast(false)
	}

	const handleClickModal = (tab: string) => {
		setCurrentTab(tab)
		setOpenModal(!openModal)
	}

	const onChangeHandler = (value: string) => {
		setCurrentSelectedTab(value)
	}

	useEffect(() => {
		getUserDataReq()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, window.location.pathname.toString().split('/')[3]])

	return (
		<div className="social-view-component pb-[20px] overflow-auto">
			{openModal && (
				<ModalFollowersAndFollowing open={openModal} targetTab={currentTab} setOpen={setOpenModal} isTarget={false} />
			)}

			{fetch ? (
				reqData &&
				reqData.name && (
					<div className="w-full flex flex-col gap-2">
						<div className="bg-white shadow-md rounded-[4px]">
							<div className="p-4">
								<div className="flex flex-col gap-10">
									<div className="relative h-[350px] flex items-end">
										<div
											className="top-[80px] ml-4"
											style={{
												width: '240px',
												height: '240px',
												position: 'relative',
												zIndex: 10,
												overflow: 'hidden',
												borderRadius: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												backgroundColor: reqData.img && reqData.img !== 'null' ? 'transparent' : '#ccc',
											}}>
											{reqData.img && reqData.img !== 'null' ? (
												<img
													src={reqData.img}
													alt="profile"
													style={{
														width: '240px',
														height: '240px',
														objectFit: 'cover',
														borderRadius: '100%',
													}}
												/>
											) : (
												<p className="data__initial w-[240px] h-[240px] text-6xl ml-2">
													{reqData.name ? reqData.name[0].toUpperCase() : ''}
												</p>
											)}
										</div>
										<div
											className="w-full h-full flex items-center cover-image-bg"
											style={{
												backgroundImage: `url(${reqData.cover})`,
												backgroundSize: 'cover',
												position: 'absolute',
												top: 0,
											}}></div>
									</div>

									<div className="flex justify-end items-center gap-4 mt-[30px] ]">
										{user && user.address && reqData.user === user.address && (
											<Button
												type="subtle"
												size="small"
												onClick={() => {
													navigate('/edit-profile/' + reqData.user, { state: reqData })
												}}
												value="Edit"
											/>
										)}
										<div className="flex items-center gap-[8px]">
											<Button
												size="small"
												value={`Following ${reqData?.following || 0}`}
												onClick={() => {
													handleClickModal('following')
												}}
											/>
											<Button
												size="small"
												value={`Followers ${reqData?.followers || 0}`}
												onClick={() => {
													handleClickModal('followers')
												}}
											/>
											{profile && profile.user && user && reqData.user !== profile.user && (
												<>
													{isFollow ? (
														<Button
															size="small"
															type="warning"
															value={'Unfollow'}
															onClick={() => handleClick(true)}
															disabled={!profile}
														/>
													) : (
														<Button
															size="small"
															type="secondary"
															value={'Follow'}
															onClick={() => handleClick(false)}
															disabled={!profile}
														/>
													)}
												</>
											)}
										</div>
									</div>
								</div>

								<div className="">
									<TextStyleBodyDefaultLarge>@{reqData ? reqData.tag : reqData.tag}</TextStyleBodyDefaultLarge>
									<TextStyleH3>{reqData ? reqData.name : reqData.name}</TextStyleH3>
									<div>
										<TextStyleBodyDefaultMedium className="text-lg font-medium text-gray-900">
											Bio
										</TextStyleBodyDefaultMedium>
										<TextStyleBodyDefault>{reqData && reqData.description}</TextStyleBodyDefault>
									</div>
								</div>
							</div>
							<div className="flex items-center cursor-pointer">
								<Tabs
									onChange={onChangeHandler}
									options={[
										{ label: 'Posts', value: 'posts' },
										{ label: 'Constellations', value: 'festivals' },
										{ label: 'Files', value: 'files' },
									]}
									value={currentSelectedTab}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col overflow-hidden pb-[90px]">
							{currentSelectedTab === 'posts' ? (
								<div className="w-full flex flex-col">
									{posts && posts.length > 0 && (
										<Feed viewProfile={true} posts={posts} profile={false} setData={false} />
									)}
								</div>
							) : currentSelectedTab === 'files' ? (
								<div className="w-full flex flex-col bg-white p-4">
									<UserFiles
										userAddress={
											window.location.pathname.toString().split('/')[3]
												? window.location.pathname.toString().split('/')[3]
												: ''
										}
									/>
								</div>
							) : (
								<div className="w-full flex flex-col bg-white p-4">
									<MyFestivals keyParam={''} user={window.location.pathname.toString().split('/')[3]} />
								</div>
							)}
						</div>
					</div>
				)
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
			<div>
				{user && (!reqData || !reqData.name) && fetch && (
					<p className={`text-[${color.text}] p-2 mt-4`}>The profile you are looking for does not exist here</p>
				)}
			</div>
		</div>
	)
}

export default SocialView
