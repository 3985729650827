import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'react-router-dom'
const Community: React.FC = () => {
	return (
		<div className="w-full h-full mt-2 mb-2 flex flex-col w-full items-center justify-center">
			<h1 className="text-bold text-3xl font-extrabold leading-9 tracking-tight mb-5 text-center">
				Frequently Asked Questions [FAQ]
			</h1>
			<p className="font-bold mt-1">General Presentation</p>
			<div className="max-w-[70%]">
				<span className="text-gray-700">
					What is Kinera? Kinera is a media platform integrated with a social network, built to allow a seamless
					convergence of creators and viewers. The platform is designed to facilitate collaborative curation, network
					moderation, and co-creation of audiovisual and streaming content.
					<br />
					<br />
					At the bottom of the left menu bar, find: What Does Kinera Alfa 1.0 Have to Offer.
				</span>
			</div>
			<div className="mt-10 flex flex-col gap-4">
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						What is Kinera?
					</AccordionSummary>
					<AccordionDetails>
						Kinera is a media platform integrated with a social network, built to allow a seamless convergence of
						creators and viewers. The platform is designed to facilitate collaborative curation, network moderation, and
						co-creation of audiovisual and streaming content.
						<br />
						<br />
						At the bottom of the left menu bar, find: What Does Kinera Alfa 1.0 Have to Offer.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						When did Kinera launch?
					</AccordionSummary>
					<AccordionDetails>
						The Kinera Network will be launched in 2025. Alfa 1.0 [Minimum Viable Product] was released on April 25,
						2024.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
					>
						How many tokens do I need to be able to navigate the testnet?
					</AccordionSummary>
					<AccordionDetails>
						Every 24 hours, you can add 2500 new play tokens. To do that, open the link at{' '}
						<Link
							className="ml-2 mr-2 text-blue-800"
							to=" https://nodleprotocol.io/?rpc=wss%3A%2F%2Fnode.kinera.network#/explorer"
						>
							Kinera chain
						</Link>
						Go to Accounts and press Transfer <br /> - Go to “send from account” and choose “BOB”
						<br /> - Transfer 2500 tokens from “BOB” to your wallet <br />- Sign and submit the transaction
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
					>
						If I want to sell my tokens, where can I go?
					</AccordionSummary>
					<AccordionDetails>
						We will use play tokens until the platform is released. There aren’t markets for play tokens.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
					>
						Is Kinera proof-of-stake?
					</AccordionSummary>
					<AccordionDetails>
						Kinera emulates the behaviour of proof-of-stake parachain in its Ranking lists. When users allocate tokens
						to their favourite videos, films, or creators, an algorithm gives them 18% yearly in new tokens.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						sx={{ fontWeight: 'bold' }}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
					>
						Is there a limit to how many tokens can be used in Staking?
					</AccordionSummary>
					<AccordionDetails>no</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What makes Kinera unique?
					</AccordionSummary>
					<AccordionDetails>
						We aim to transform and improve the quality and experience of media consumption.
						<br />
						<br />
						Our services have no fees, do not aim for profit and are free from advertising. Kinera doesn't employ
						algorithms to manipulate your experience. We are not in a battle for human attention.
						<br />
						<br />
						Our community members will have the tools to determine what is true and false, what is beautiful and what is
						sublime, and to empower the collective and help people grow as social beings.
						<br />
						<br />
						We embrace responsibility for selecting the content that resonates with each of us, free from algorithmic
						influence. We want to contribute to the end of the illusion that humans are powerless. We are not well
						served by the opaque algorithms dictating what content we see.
						<br />
						<br />
						In our space, with our rules, we can dispute the dominant narratives and challenge the existing
						institutions, their interpretation of the world and their continual advertisement against all possibilities
						of change because “things are great as they are”.
						<br />
						In economic terms, we are the place where curators and creators organise, show and sell or are rewarded for
						their content. We are a community focused on the relevance, quality and value of the information produced
						and how creators benefit. How do these creators monetise the content they produce and leverage the earnings
						of all creators in the network?
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How does Kinera prevent constellation games from being manipulated by human interference or Artificial
						Intelligence agents from winning?
					</AccordionSummary>
					<AccordionDetails>
						We accept that all markets can be manipulated to some degree. Our voting mechanism is set to minimise the
						ability of single participants to interfere in the process by voting with a large number of tokens at the
						last minute. Therefore, in the last 10% of the time available for voting, the weight of the vote starts to
						decrease until it reaches zero when the competition ends.
						<br />
						<br />
						To eliminate interference from bots or AI agents, all members of the platform must prove that they are
						humans with unique accounts/wallets.
						<br />
						<br />
						In the near future, curators can create constellation games where users only see the voting results when the
						competition ends.
						<br />
						<br />
						Organised attacks on the games will be minimised by specialised network monetisation and group behaviour
						analysis.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What is Kinera’s present stack?
					</AccordionSummary>
					<AccordionDetails>
						White Paper: available upon request GitHub: available upon request{' '}
						<Link
							to="https://drive.google.com/file/d/1zoowL-_N_BlzWLCeMEqmjDXY3JZ6HDQL/"
							className="ml-2 mr-2 text-blue-800"
						>
							Light paper
						</Link>
						<br />
						<br />
						Webpage:{' '}
						<Link to="https://kinera.network/" className="ml-2 mr-2 text-blue-800">
							App
						</Link>{' '}
						X/Twitter account:{' '}
						<Link to=" https://twitter.com/KineraNetwork" className="ml-2 mr-2 text-blue-800">
							App
						</Link>
						<br />
						<br />
						The Invisible Hans Lab is the legal structure building Kinera,{' '}
						<Link to="https://invisiblehandlab.org/" className="ml-2 mr-2 text-blue-800">
							Invisible hand
						</Link>
						Team credentials are at the bottom of the same page.
						<br />
						<br />
						The outline for the Kine Foundation is at{' '}
						<Link to=" https://kine.foundation/" className="ml-2 mr-2 text-blue-800">
							Kine Foundation
						</Link>
						<br />
						<br />
						All our websites have blog posts that expand on our intentions and goals. [Small changes can be made after
						an English review.]
						<br />
						<br />
						Kinera [Minimum Viable Product] node can be found at{' '}
						<Link to=" https://d-app.kinera.network/" className="ml-2 mr-2 text-blue-800">
							d-app
						</Link>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Is there a limit to how many entries I can propose in a Constellation or to a Ranking?
					</AccordionSummary>
					<AccordionDetails>No</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Can I propose a new Ranking?
					</AccordionSummary>
					<AccordionDetails>
						In the Alfa 1.0 phase, the platform comes with a fixed set of rankings spanning: 1. All Time Best Films.
						Historical landmarks and life-changing fiction and documentaries.
						<br />
						2. Highlight the best according to a Geographical distribution.
						<br />
						3. Rankings that support themes that can later be used to create streaming channels.
						<br />
						After the platform launch, any user can propose new rankings.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						With so many Rankings, films, and videos, what is the best way to use my voting power?
					</AccordionSummary>
					<AccordionDetails>
						If you want to make Kinera Rankings a place of reference and want to contribute in a way to make this
						possible, we present two strategies:
						<br />
						1. Look at the videos, films or creators on a list, see what is missing and propose it.
						<br />
						2. Vote to make lists more according to your sense of what's best. Vote to balance the present choices you
						see represented. Look at the number of tokens you want to allocate and use them to make the list closer to
						your tastes, values, and beliefs.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What resources will Kinera have in the last stage when version 1.0 is released?
					</AccordionSummary>
					<AccordionDetails>
						The Kinera 1.0 final release will have the following features added to our current ones.
						<br />
						- Automated Subscription models for streaming content. Smart contracts to mediate the way creators charge
						for content and offer access to their archives <br />
						- Custom privacy settings for what you share on the Social Page, to all your votes, and transactions <br />
						- Different options to store your films, different speeds to access and prices <br />
						- Users can use network features according to their level of participation and the quality of the
						information they produce <br />
						- Community content moderation at scale <br />
						- Governance and treasury tools <br />- Channel creation studio
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Why do you use the Kusama and Polkadot framework?
					</AccordionSummary>
					<AccordionDetails>
						The decision to build Kinera with Substrate was deliberate, as it offers clear advantages. Substrate stands
						out as the most responsive and adaptable blockchain and has the governance tools we want to provide to our
						community. Its interoperability facilitates task delegation to Polkadot parachains and enables seamless
						connections with different blockchains.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						When will you release the White Paper?
					</AccordionSummary>
					<AccordionDetails>
						The White Paper will be made public when we set a crowdfunding campaign date.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What is our funding strategy?
					</AccordionSummary>
					<AccordionDetails>
						Kinera, until the Alfa 1.0 release, has been financed by the people working on the project. The determining
						factor for our future funding strategy is our goal to protect the curatorial value of the Kinex token and
						its use as a tool to navigate the platform. Therefore the aim is to deliver 100% of the tokens to current
						and future members of the Kinera community.
						<br />
						The first step in seeking external funding is our proposal to the Polkadot DOT treasury, seeking financial
						support and, at the same time, opening the project to feedback from the Polkadot community.
						<br />
						We allocated 2.5% of the tokens to a seed funding round and 40% to the main funding event, a crowdfunding
						campaign capped at 1000 Euros.
						<br />
						Kinera will be community-owned, self-governed and self-moderated. All funding opportunities will be first
						made public in our newsletter.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How can I join the community?
					</AccordionSummary>
					<AccordionDetails>
						Anyone can join the community by following the instructions on the website.
						<br />
						To follow the development of the platform and receive updates about new features, subscribe to our
						newsletter. Go to https://kinera.network/ at the bottom of the page.
						<br />
						Follow our X/Twitter account at: https://twitter.com/KineraNetwork
						<br />
						To group test Kinera’s features, join the Discord Channel “Kinera Community Testers”.
						<br />
						If you want to volunteer for a role as a future ambassador, fill out the form here;
						<br />
						https://kine.foundation/applicants/project-grants/apply <br />
						Ambassadors will be selected and grouped based on their skills, starting at different times. Be prepared for
						a slow process; the network will be released in 2025.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Will you have Airdrops for the community?
					</AccordionSummary>
					<AccordionDetails>
						Yes, we will. <br />
						<br />
						0.5% of the total number of tokens will be distributed to all the wallets [created before April 9th] voting
						on the Kinera proposal to the Polkadot treasury, no matter how they vote or the outcome.
						<br />
						In September 2024, we will open the games that will lead to the distribution of 5% of Kinex tokens to the
						most dynamic users active in the production testnet.
						<br />
						Further token incentives will be released as new features become available
						<br />- Youtubers and cinema professionals transfer their videos to a Web 3 environment
						<br />- Streaming Channels Creators achieve specific metrics
						<br />- Token prices for content produced
						<br />- Treasury support
						<br />- Additional future token distributions, yet to be defined, will be announced in our blogs and
						newsletters.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What is the proposal for the Token allocation?
					</AccordionSummary>
					<AccordionDetails>
						Token allocations are planned as follows: <br />
						42.5% of the tokens are assigned to investors <br />
						35% go to the Kine Foundation <br />
						13.5% to the community of users <br />
						5% to Core time <br />
						3% to bug bounties programs, future team members and partnerships <br />
						1% to core contributors (the first team and advisors)
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Are you looking for collaborators and advisors?
					</AccordionSummary>
					<AccordionDetails>
						If you share Kinera's vision and want to contribute to the reinvention of the video streaming narrative, we
						seek collaborators and advisors who can:
						<br />
						<br />
						- Write about the white paper, tokenomics, decentralised media or community building.
						<br />
						<br />
						- Review the English in our materials
						<br />
						<br />
						- Contribute in another way not specified but that you believe can be useful.
						<br />
						We also call for advisers, Polkadot experienced investors, whales, or other very skilled participants
						aligned with our proposal that can bring funding solutions that don’t imply the sale of future tokens. If
						you feel the call to join us and contribute, let's talk: <br />
						<br />
						https://calendar.google.com/calendar/appointments/AcZssZ376eN9LLINWxMDwxgjaR49KyegHzmifaG9PgQ=
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Who is building Kinera, and how are you structuring it?
					</AccordionSummary>
					<AccordionDetails>
						The Invisible Hans Lab, a company based in Porto, Portugal, is the present legal structure,
						https://invisiblehandlab.org/ Team credentials are at the bottom of the same page]
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Jobs and open applications
					</AccordionSummary>
					<AccordionDetails>
						We are looking for a skilled substrate developer. If you are interested in this position, let's talk here:
						https://calendar.google.com/calendar/appointments/AcZssZ376eN9LLINWxMDwxgjaR49KyegHzmifaG9PgQ=
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						I want to form a partnership with Kinera. Who should I contact?
					</AccordionSummary>
					<AccordionDetails>
						We will only be available to discuss partnerships once we have a date and a place to launch the Initial Coin
						Offering (ICO).
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						Who can I contact regarding a press inquiry?
					</AccordionSummary>
					<AccordionDetails>
						When a date is set for the ICO, the team and its representatives will be available to respond to press
						inquiries. The launch of the ICO will be accompanied by a press package and comprehensive information about
						the project.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What is a REP token?
					</AccordionSummary>
					<AccordionDetails>
						Reputation tokens (REP) are a tool to acknowledge and reward the positive contributions made by community
						members to enhance the value of the information available on the platform. Users have two types of
						reputation tokens: lifelong reputation tokens and 200Rep. 200Rep is a ranking system that reflects the
						reputation earned by members over the previous 200 days. For the sake of simplicity, this 200Rep is the
						reputation metric used for decision-making across various aspects of the platform. Reputation is crucial for
						social tools to establish connections with other users and achieve Senior Member status that grants access
						to all platform features. These tokens serve as a measure of the trust that the community places in each
						user, representing their influence within the community.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How do I build a reputation on Kinera as a Junior?
					</AccordionSummary>
					<AccordionDetails>
						Everyone joining the platform starts out as a Junior. Junior Members can earn a reputation by moderating
						random decisions with skilled and unskilled moderators who stake tokens. These tokens can be lost when
						voting with the minority. Junior members earn one reputation token if they vote with the majority. Junior
						Members can also earn tokens by:
						<br />
						- Completing a quiz about community rules, passing or failing earning/losing
						<br />
						- Earning votes for their actions from approved moderators <br />
						- When their constellation proposals are approved <br />
						- Applying to take part in resolving issues made public on the treasury page
						<br />
						- Writing blog posts and reviews
						<br />
						- Providing proof of their identity (3 levels)
						<br />
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How do I earn tokens as a Senior?
					</AccordionSummary>
					<AccordionDetails>
						Senior Members can earn tokens by: <br />
						1 - Submitting a comment on films or constellations with more than 200 characters and in compliance with the
						community rules. <br />
						2 - Winning treasury tips <br />
						3 - Staking Kinex in new Television channels <br />
						4 - Staking in ranking with conviction, committing your tokens for a 6-month period <br />
						5 - Commenting on a new video or film with a text of more than 250 characters <br />
						6 - By participating in referendums, polls, and other community surveys <br />
						7 - By creating an open TV Channel. Approval of a subscription TV Channel <br />8 - When receiving Treasury
						Tips
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How do I become a Senior Curator?
					</AccordionSummary>
					<AccordionDetails>
						All participants start as Junior Curators, with limited access to the functions of the platform and must
						earn a reputation to rise to the Senior level.
						<br />
						For Juniors, there is a fast track to gaining Senior status: <br />
						A moderator can safelist a Junior member to Senior status by allocating Kinex tokens to his actions on the
						platform. <br />
						Users can gain Senior status by allocating a yet-to-be-defined number of Kinex tokens. <br />
						<br />
						In both cases, the Kinex tokens are returned five days after the user earned the REP tokens, allowing other
						members to become seniors. Moderators still monitor these users and have their publications highlighted to
						be more visible. With a yet-to-be-defined number of REP tokens earned and kept in the previous 24 hours, a
						Junior member is moved to the Senior level. <br />
						<br />
						Junior members with negative REP must allocate Kinex tokens to publish on a timeline.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What does a curator do, and how can I become one?
					</AccordionSummary>
					<AccordionDetails>
						All members can be curators, engaging at a level in accordance with their experience and interests. As a
						curator, you can submit content, propose constellations and festivals, share your knowledge and views with
						the community, write reviews and participate in voting.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						What is the role of a Moderator on Kinera?
					</AccordionSummary>
					<AccordionDetails>
						Moderators are community members upholding civility on the Kinera platform. They handle content disputes,
						address infractions to community rules, and contribute to maintaining an environment free of toxicity, hate,
						and disinformation. Moderators are volunteers who call and resolve violations of the community rules in
						moderation events. Only senior members above the Top 15% of Reputation can become guardians. They do this by
						changing their status in the settings menu. The moderation scope: users' posts, comments, constellations,
						treasury proposals and subscription channels, the names proposed to be used in rankings or videos or films
						proposed to be in the list.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2-content"
						id="panel2-header"
						sx={{ fontWeight: 'bold' }}
					>
						How do I control my user experience?
					</AccordionSummary>
					<AccordionDetails>
						You can tailor your preferences by uploading templates and customise your profile or brand. The aim is to
						ensure that your online presence is as private as you desire and that your profile genuinely reflects your
						unique identity. In the settings on your social profile, you have comprehensive control over every aspect of
						your user experience. You are responsible for deciding how your personal space interacts with the Kinera
						community. Whether it involves managing the extent of moderation, assuming the role of a Senior Moderator,
						or participating as a Scout, the choice is yours.
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	)
}

export default Community
