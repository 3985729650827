import React, { useState } from  'react'
import Button from 'src/components/_Dsc/Button'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyDefault, TextStyleCaption } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { Community } from 'src/pages/communities'
import { communityService } from 'src/service/community/communityService'

interface Props {
    open: boolean
    setOpen: (param:boolean) => void
    community: Community
}

interface PendingApproveUser {
    communityName: string
    socialName: string
    user: string
}

const PendingApprovalModal: React.FC<Props> = ({open,setOpen, community}) => {
    const { user, setOnQueue } = React.useContext(KineContext)
    const [pendingApproves, setPendingApproves] = useState<PendingApproveUser[]>([] as PendingApproveUser[])
    const [inFetch, setInFetch] = useState<boolean>(true)
    const handleClose = () => {
        setOpen(false)
    }

    const getCommunityPendingApproval = async() => {
        setInFetch(true)
        let req = await communityService.pendingEntryRequests(community.id)
        setPendingApproves(req)
        setInFetch(false)
    }

    const approveMemberToEntryCommunity = async (pending:PendingApproveUser) => {
        setInFetch(true)
        setPendingApproves([])
        await communityService.approveMember(user, community.id, pending.user, pending.socialName, community, setOnQueue)
        await getCommunityPendingApproval()
        setInFetch(false)
    }
    React.useEffect(() => {
        if(community && community.id) {
            getCommunityPendingApproval()
        }
    },[community])

    return (
        <Modal title='Pending Approval' open={open} onCancel={handleClose} onClose={handleClose} cancelLabel='Close'>
            {
                inFetch
                ? <Loading  />
                :<div className='w-full flex flex-col'>
                    {
                        pendingApproves && pendingApproves.length
                        ? pendingApproves.map((pending: PendingApproveUser, index: number) => (
                           
                            <div className='flex w-full items-center justify-between'>
                                
                                <div className='flex items-baseline gap-2'>
                                    <InitialSimbol profile={{img: undefined, name: pending.socialName}} />
                                    <TextStyleBodyDefault>{pending.socialName}</TextStyleBodyDefault>
                                    <TextStyleCaption className='truncate max-w-[70%]'>{pending.user}</TextStyleCaption>
                                </div>
                                <Button value='Approve' size='small' onClick={() => {approveMemberToEntryCommunity(pending)}}/>
                            
                            </div>
                           
                        ))
                        : <TextStyleBodyDefault>You have no pending requests</TextStyleBodyDefault>
                    }
                </div>
            }
        </Modal>
    )
}

export default PendingApprovalModal