import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Tabs from 'src/components/_Dsc/Tabs'
import MyPhotos from './components/myPhotos'
import MyVideos from './components/myVideos'
import './styles.scss'
import KineContext from 'src/context/KineContext'
import { Link, useNavigate } from 'react-router-dom'
import { TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
const path = [
	{ label: 'Home', link: '/' },
	{ label: 'My Documents', link: '' },
]

const options = [
	{
		label: 'My Photos',
		value: 'photos',
	},
	{
		label: 'My Videos',
		value: 'videos',
	},
]
const MyDocuments: React.FC = () => {
	const [isSelected, setIsSelected] = useState('photos')
	const { user } = React.useContext(KineContext)
	const navigate = useNavigate()

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
	}
	useEffect(() => {
		if (!user) {
			navigate('/')
		}
	}, [])

	return (
		<div className="max-app page-padding pb-max documents">
			<Breadcrumb routes={path} />
			<CardInner className="bg-white">
				<CardContainer>
					<div className="w-full flex items-center ">
						<Tabs onChange={setFilterNotifications} options={options} value={isSelected}></Tabs>
					</div>
					<div className="mt-4">
						<div className="w-full p-4 items-center justify-center text-center flex">
							<TextStyleBodyDefaultMedium>
								Photos and videos saved in the{' '}
								<Link
									to="https://tools.kinera.network/image"
									target="_blank"
									className="ml-1 mr-1 text-blue-400 underline hover:bg-gray-100">
									Creation tools
								</Link>{' '}
								can be published permanently on your network, just click on the desired image and publish it, this will
								prevent your image from disappearing
							</TextStyleBodyDefaultMedium>
						</div>
						{isSelected === 'photos' ? <MyPhotos /> : <MyVideos />}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default MyDocuments
