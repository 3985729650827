import { ApiPromise, WsProvider } from '@polkadot/api'
import { web3FromAddress } from '@polkadot/extension-dapp'
import { Keyring } from '@polkadot/api'
import jsonrpc from '@polkadot/types/interfaces/jsonrpc'
import { User, UserProfile } from 'src/interface'
import { Community } from 'src/pages/communities'
import API from '../api'
import { useContext } from 'react'
import KineContext from 'src/context/KineContext'

async function api() {
	try {
		const wsProvider = new WsProvider('wss://dev.kinera.network')
		const api = await ApiPromise.create({
			provider: wsProvider,
			rpc: jsonrpc,
		})
		return api
	} catch (error) {
		console.log(error)
	}
}

const getCommunities = async (type: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.communitiesByVoteResult(type)
			if (req) {
				let formated: any = req.toHuman()

				formated.forEach((value: any) => {
					return maped.push(value)
				})
			}
			return maped
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunitiesByUser = async (user: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.userCommunities(user)
			if (req) {
				let formated: any = req.toHuman()
				formated.forEach((value: any) => {
					return maped.push(value)
				})
			}
			return maped
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunitiesById = async (id: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.communities(id)
			if (req) {
				let formated: any = req.toHuman()

				return formated
			}
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunity = async (id: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.communities(id)
			if (req) {
				let formated: any = req.toHuman()

				return formated
			}
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const applyForCommunity = async (user: any, community: Community) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.addMember(community.id, user.name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized && community.communityType === 'Public') {
				createDbRegisterUserCommunity(community.id, user.address, community.communityType, user.name)
			}
		})
	} catch (err) {
		console.log(err)
	}
}

const getCurrentAccBlock = async () => {
	let apiCommunity = await api()
	const get: any = await apiCommunity?.query.system.number()
	return get.toHuman()
}

const createCommunityProposal = async (
	user: any,
	title: string,
	description: string,
	reason: string,
	type: string,
	icon: string,
	setState: (param: boolean) => void,
	handleConclude: () => void,
	handleGlobalMessage: (param: string) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		// console.log("title, description, type, icon, user.name, reason", title, description, type, icon, user.name, reason)
		const send = connect?.tx.communitiesModule.createCommunity(title, description, type, icon, user.name, reason)
		handleGlobalMessage('Waiting for transaction to complete.')
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				setState(false)
				handleConclude()
			} else {
				setState(true)
			}
		})
	} catch (err) {
		setState(true)
		handleGlobalMessage('An error occurred while sending the request to your wallet, log in again')
		console.log(err)
	}
}

const submiteCommunityVote = async (
	user: any,
	id: string,
	type: boolean,
	setState: (param: boolean) => void,
	community: Community,
	handleGlobalMessage: (param: string) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.submitVote(id, type, user.name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				if (type === true) {
					let formated = parseInt(community.votesApprove) + 1
					community.votesApprove = formated.toString()
				} else {
					let formated = parseInt(community.votesReject) + 1
					community.votesReject = formated.toString()
				}
				setState(false)
			} else {
				setState(true)
			}
		})
	} catch (err) {
		handleGlobalMessage('An error occurred while sending the request to your wallet, log in again')
		console.log(err)
	}
}

const approveMember = async (
	user: any,
	id: string,
	address: string,
	name: string,
	community: Community,
	setState: (param: boolean) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.approveMember(id, address, name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				createDbRegisterUserCommunity(community.id, address, community.communityType, name)
				setState(false)
			} else {
				setState(true)
			}
		})
	} catch (err) {
		console.log(err)
	}
}

const createDbRegisterUserCommunity = async (
	community_id: string,
	user_address: string,
	type: string,
	name: string,
) => {
	let payload = {
		user_address: user_address,
		community_id: community_id,
		type: type,
		name: name,
	}
	try {
		await API.post(`/api/community/`, payload)
	} catch (e) {
		console.log(e)
	}
}

const verifyUserExistInCommunity = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityMembers(communityId)
		if (req) {
			let formated: any = req.toHuman()
			let verify = false

			if (formated && formated.length) {
				formated.forEach((val: { user: string }) => {
					if (val.user === user.address) {
						verify = true
					}
				})

				return verify
			} else {
				return false
			}
		} else {
			return false
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

const verifyPendingRequest = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.pendingEntryRequests(communityId)
		if (req) {
			let formated: any = req.toHuman()
			let verify = false

			if (formated && formated.length) {
				formated.forEach((val: { user: string }) => {
					if (val.user === user.address) {
						verify = true
					}
				})

				return verify
			} else {
				return false
			}
		} else {
			return false
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

const getAllMembersInCommunity = async (communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityMembers(communityId)
		if (req) {
			let formated: any = req.toHuman()
			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const verifyUserVoteCommunity = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityCreateVoters(communityId)
		if (req) {
			let formated: any = req.toHuman()

			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const pendingEntryRequests = async (communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.pendingEntryRequests(communityId)
		if (req) {
			let formated: any = req.toHuman()

			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

export const communityService = {
	getCommunities,
	applyForCommunity,
	verifyUserExistInCommunity,
	createCommunityProposal,
	getCommunity,
	getCurrentAccBlock,
	submiteCommunityVote,
	verifyUserVoteCommunity,
	getCommunitiesByUser,
	getCommunitiesById,
	getAllMembersInCommunity,
	verifyPendingRequest,
	pendingEntryRequests,
	approveMember
}
