import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
const About: React.FC = () => {
	return (
		<div className="mb-2 flex flex-col items-center justify-center mb-2 w-full items-center justify-center">
			<h1 className="text-bold text-3xl font-extrabold leading-9 tracking-tight  text-center">
				Features in the first release
			</h1>

			<h1 className="text-bold text-xl font-extrabold leading-9 tracking-tight mt-5 text-center">Presentation</h1>
			<p className="text-center mt-2">
				Kinera is a social network for media creation that rewards users for ranking and classifying content using
				tokenised voting and allocation mechanisms.
			</p>

			<h2 className="m-2">In our first release, Alfa 1.0, you can test the following features:</h2>

			<ol className="flex flex-col items-center justify-center">
				<li className="flex flex-col items-center justify-center">
					<h1 className="font-bold text-lg mt-2">Rankings</h1>
					<p className="text-center mt-4">
						Propose or rank films, videos or creators. If you propose a new entry, you must allocate a minimum of 10
						KinexPlay Tokens, the value you store here can't be moved. All the tokens that are nested in Rankings offer
						an 18% annual reward if left on the lists during that time.
					</p>
					<p className="text-center mt-4">
						<strong>How to do it?</strong>
						<br /> Find Rankins at the top of the screen. Here you can vote or propose new entries.
					</p>
					<p className="text-center">
						Note
						<br /> You can remove your tokens from the ranking anytime, but the token rewards stop after you withdraw
						them.
					</p>
				</li>

				<li className="flex flex-col items-center justify-center mt-4">
					<h1 className="font-bold text-lg mt-2">Constellations</h1>
					<p className="text-center">
						You can create video or film competitions with YouTube links. Start by writing a Description of your goals
						and a question, and choose the video or films that match your challenge.
					</p>
					<p className="text-center m-2 mt-4">
						<h1 className="font-bold text-lg">Example of a Constellation</h1>
						Description [optional]
						<br />
						“I sincerely believe that banking establishments are more dangerous than standing armies and that the
						principle of spending money to be paid by posterity under the name of funding is but swindling futurity on a
						large scale.” –Thomas Jefferson
						<br />
						Question <br />
						Which is the Best Documentary about money?
						<br />
						List of Films <br />
						Money For Nothing. Inside The Federal Reserve <br />
						https://www.youtube.com/watch?v=LJkljVZ_M3w
						<br />
						Capitalism. A love story. 2009 <br />
						https://www.youtube.com/watch?v=cK4H79j1I4A
						<br />
						The Secret of Oz: The Hidden Economic symbolism in the original Wizard of Oz <br />
						https://www.youtube.com/watch?v=cFeRKaaktyM
						<br />
						The City of London – Money and Power (2011) <br />
						https://www.youtube.com/watch?v=OYfnkLurLA8 Mone <br />y as Debt - Full Documentary <br />
						https://www.youtube.com/watch?v=4AC6RSau7r8 <br />
					</p>
					<p className="text-center mt-2">
						You can Publish your proposal right away or save it for a later date to add more entries and finalise before
						submission
					</p>

					<p className="text-center mt-10">
						<strong>How to do it?</strong>
						<br /> Go to My Constellations and then press Create Constellation. Make sure you:
					</p>
					<div className="flex flex-col items-center gap-2 ">
						<p className="text-center">1. Have the YouTube links for the videos you want to use ready</p>
						<p className="text-center">2. Prepare a description or a quotation to give context</p>
						<p className="text-center">
							3. Define how long you want this competition to last. Remember that you must allow enough time for the
							community to watch all your proposals.
						</p>
					</div>
				</li>

				<li className="flex flex-col items-center justify-center mt-10">
					<h1 className="font-bold text-lg mt-2">Voting Rules</h1>
					<p className="text-center mt-5">
						In the final 10% of the voting period, the weight and value of each vote begin to decrease linearly,
						ultimately reaching zero at the very last second. This rule prevents last-minute voters from subverting the
						outcome.
					</p>
					<p className="text-center mt-5">
						The users who vote for the winner receive the tokens from those who vote for the less favoured entries.
					</p>
					<p className="text-center mt-5">2% of the tokens in the competition go to the curator - you!</p>
					<p className="text-center mt-5">
						The winners of each constellation are published on the Kinera Winners Twitter account and on the Telegram
						Channel.
					</p>
				</li>

				<li className="flex flex-col items-center justify-center mt-10">
					<h1 className="font-bold text-lg mt-2">SEE</h1>
					<p>This is the place where you can find the winning videos from constellations.</p>
					<p className="text-center mt-5">
						<strong>How to do it?</strong>
						<br />
						Press SEE at the top of the screen and choose the curated videos and films from the archive that you want to
						see.
					</p>
					<p className="text-center mt-4">
						Here we highlight Crypto, Comedy and Artificial Intelligence. These will be the first topics for streaming
						channel creation.
					</p>
				</li>

				<li className="flex flex-col items-center justify-center mt-10">
					<h1 className="font-bold text-lg mt-2">Social Page</h1>
					<p className="text-center mt-5">
						Lists your constellations and allows you to publish videos, photos or texts, interact with other users'
						comments or posts, and follow your favourite community members. You can use this space to discuss or promote
						the videos, films, the creators or rankings you like best.
					</p>
					<p className="text-center mt-5">
						<strong>How to do it?</strong>
						<br />
					</p>
					<p className="text-center">
						Go to the Social Network on the menu on the left.
						<br />
						Use the Public Profile to see your publications or to edit your profile.
					</p>
					<p className="font-bold text-center mt-5">Note</p>
					<p className="text-center">
						{' '}
						You need to create a social profile and claim for tokens to to be able to post.{' '}
					</p>
				</li>

				<li className="flex flex-col items-center justify-center text-center mt-10">
					<h1 className="font-bold text-lg mt-2">Metrics</h1>
					<p>[To be released soon]</p>
					<p>The place where we update Kinera’s metrics and statistics</p>
				</li>

				<li className="flex flex-col items-center justify-center mt-10">
					<h1 className="font-bold text-lg mt-2">Settings</h1>
					<p>For now, you can change platform colours. More options will will be released soon.</p>
				</li>
				<li className="flex flex-col items-center justify-center mt-10">
					<h1 className="font-bold text-lg mt-2">What’s Next?</h1>
					<p>Our monthly releases will be packed with new features. Make sure you subscribe to the newsletter.</p>
				</li>
			</ol>
		</div>
	)
}

export default About
