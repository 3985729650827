import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Dropdown from 'src/components/_Dsc/Dropdown'
import InputText from 'src/components/_Dsc/InputText'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium, TextStyleH1, TextStyleH2 } from 'src/components/_Dsc/typography'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'

const path = [
	{ label: 'Communities', link: '/communities' },
	{ label: 'Create Community proposal', link: '' },
]

const options = [
	{ label: 'Public', value: 'Public' },
	{ label: 'Private', value: 'Private' },
]

const CreateCommunityProposal: React.FC = () => {
	const { user, setOnQueue, handleGlobalMessage } = React.useContext(KineContext)

	const [description, setDescription] = useState<string>('')
	const [reason, setReason] = useState<string>('')
	const [currentType, setCurrentType] = useState<string>(options[0].value)
	const [title, setTitle] = useState<string>('')
	const [urlIcon, setUrlIcon] = useState<string>('')
	const [inFetch, setInFetch] = useState<boolean>(false)
	const navigate = useNavigate()

	const handleChange = (e: any, set: any, param?: boolean) => {
		set(e)
	}

	const handleChangeReason = (e: any, set: any, param?: boolean) => {
		set(e)
	}

	const changeCurrentType = (param: string[]) => {
		setCurrentType(param[0])
	}

	const createCommunity = async () => {
		if (user && user.address) setInFetch(true)
		await communityService.createCommunityProposal(
			user,
			title,
			description,
			reason,
			currentType,
			urlIcon,
			setOnQueue,
			handleConclude,
			handleGlobalMessage
		)

		setInFetch(false)
	}

	const handleConclude = () => {
		navigate('/communities')
	}

	const handleUrlIconChange = (url: string) => {
		setUrlIcon(url)
	}

	const handleNameChange = (url: string) => {
		setTitle(url)
	}

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex flex-col">
							<TextStyleH1>Create Community proposal</TextStyleH1>
							<TextStyleBodyDefault>Create a community proposal</TextStyleBodyDefault>
						</div>
					</div>

					<div className="mt-4 p-4 rounded-[4px] gap-2 flex flex-col">
						<div className="flex flex-col gap-1 relative">
							<label className={`block font-bold  `} htmlFor="description">
								Proposal reason:
							</label>
							<textarea
								className={`border rounded py-4 px-2 text-grey-darkest w-full ${reason.length > 500 ? 'has-error' : ''}`}
								onChange={event => handleChangeReason(event.target.value, setReason, true)}
								name="description"
								id="description"
								value={reason}
								placeholder="Description..."
							/>
							<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
								{reason.length}/500
							</p>
						</div>

						<div className="flex flex-col  justify-between gap-1">
							<div className="flex flex-col gap-1 relative">
								<label className={`block font-bold `} htmlFor="festivalTitle">
									Community Name:
								</label>
								<InputText invalid={title.length > 60} value={title} onChange={handleNameChange} placeholder="Beta testers..." />
								<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
									{title.length}/60
								</p>
							</div>
							
						</div>

						<div className="flex flex-col gap-1 relative">
							<label className={`block font-bold  `} htmlFor="description">
								Community Description:
							</label>
							<textarea
								className={`border rounded py-4 px-2 text-grey-darkest w-full ${description.length > 300 ? 'has-error' : ''}`}
								onChange={event => handleChange(event.target.value, setDescription, true)}
								name="description"
								id="description"
								value={description}
								placeholder="Description..."
							/>
							<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
								{description.length}/300
							</p>
						</div>

						<div className="flex flex-col  justify-between gap-1">
							<label className={`block font-bold `} htmlFor="festivalTitle">
								Community Type:
							</label>
							<div className="bg-white rounded-[4px] w-fit">
								<Dropdown options={options} value={[currentType]} onChange={changeCurrentType}></Dropdown>
							</div>
						</div>

						<div className="flex flex-col  justify-between gap-1">
							<div className="flex flex-col gap-1 relative">
								<label className={`block font-bold `} htmlFor="festivalTitle">
									Community Icon:
								</label>
								<InputText invalid={urlIcon.length > 300} value={urlIcon} onChange={handleUrlIconChange} placeholder="Url icon..." />
								<p className="bg-white text-gray-700 -mt-[5px] w-fit justify-end flex absolute bottom-[2px] p-1 right-[10px]">
									{urlIcon.length}/300
								</p>
							</div>
						</div>
						{
							urlIcon.length > 300 || description.length > 300 || title.length > 60 || reason.length > 500
							? 
							<TextStyleBodyDefaultMedium className='text-red-700 w-full items-end flex justify-end'>
								Check field capacity before proceeding
							</TextStyleBodyDefaultMedium>
							: ''
						}
						<div className="flex flex-col  justify-between gap-1 items-end">
							<Button
								value="Create proposal"
								size="small"
								disabled={urlIcon.length > 300 || description.length > 300 || title.length > 60 || reason.length > 500 || !title || !description || !urlIcon || !user || inFetch}
								onClick={createCommunity}
							/>
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default CreateCommunityProposal
