import React from 'react'

interface Props {
	icon: string
	counter?: boolean
	number?: number
	size?: string
	color?: string
	type?: string
}

const DscIcons: React.FC<Props> = ({ icon, counter, number, size, color, type }) => {
	return (
		<div className="flex items-center relative hover:opacity-70 icon-dsc">
			<span className={`${size && `text-[${size}px]`} text-[${color}] material-symbols-outlined text-black `}>
				{icon}
			</span>
			{counter && (
				<div
					className="absolute text-yellow-300 h-[20px] p-[6px] rounded-[100%] flex items-center justify-center bg-black"
					style={{ top: '-10px', right: '-16px' }}>
					{number}
				</div>
			)}
		</div>
	)
}

export default DscIcons
