import React, { useState, useEffect } from 'react'
import '../styles.scss'

interface Props {
	start: string // Start block as string (with commas)
	end: string // End block as string (with commas)
}

const TimeUntilEnd: React.FC<Props> = ({ start, end }) => {
	const [counter, setCounter] = useState<number>(0)
	const [timeExpired, setTimeExpired] = useState<boolean>(false)
	const [isVerify, setIsVerify] = useState<boolean>(true)

	useEffect(() => {
		if (start && end) {
			setIsVerify(true)

			// Remover vírgulas e converter para número
			let startBlock = parseInt(start.replace(/,/g, ''), 10)
			let endBlock = parseInt(end.replace(/,/g, ''), 10)

			// Calculando a diferença em blocos e multiplicando por 6 segundos por bloco
			let difference = Math.abs(endBlock - startBlock) * 6

			setCounter(difference)
			const interval = setInterval(() => {
				setCounter((prevCounter: number) => {
					if (prevCounter > 0) {
						return prevCounter - 1
					} else {
						setTimeExpired(true)
						clearInterval(interval)
						return 0
					}
				})
				if (isVerify) {
					setIsVerify(false)
				}
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [start, end])

	return (
		<div>
			{!isVerify && (
				<p className="flex items-center justify-center flex-col">{timeExpired ? 'Ended' : formatTime(counter)}</p>
			)}
		</div>
	)
}

// Função auxiliar para formatar o tempo em JSX
const formatTime = (seconds: number): JSX.Element => {
	const days = Math.floor(seconds / (3600 * 24))
	seconds %= 3600 * 24 // segundos restantes após calcular dias
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = seconds % 60

	// Formatar como "Xd:HH:MM" se for mais de um dia
	if (days > 0) {
		return (
			<div className="calendar-body flex items-center gap-2">
				<div className="p-1">
					<p className="calendar-number">{days}</p>
					<p>Days</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(hours).padStart(2, '0')}</p>
					<p>Hours</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(minutes).padStart(2, '0')}</p>
					<p>Minutes</p>
				</div>
			</div>
		)
	} else {
		// Formatar como "HH:MM:SS" se for menos de um dia
		return (
			<div className="calendar-body flex items-center gap-2">
				<div className="p-1">
					<p className="calendar-number">{String(hours).padStart(2, '0')}</p>
					<p>Hours</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(minutes).padStart(2, '0')}</p>
					<p>Minutes</p>
				</div>
				<div className="p-1">
					<p className="calendar-number">{String(remainingSeconds).padStart(2, '0')}</p>
					<p>Seconds</p>
				</div>
			</div>
		)
	}
}

export default TimeUntilEnd
