import React, { useState } from 'react'
import { DsAccordionContainer, DsAccordionInternal, DsAccordionTitle, DsAccordionContent, SmallTitle } from './styles'

import { Tooltip } from '@mui/material'
import DscIcons from '../icons'
import { TextStyleBodyDefault, TextStyleBodyMedium } from '../typography'

interface AccordionProps {
	children: React.ReactNode
	title: string
	size?: string
	description?: string
}

export const Accordion: React.FC<AccordionProps> = ({ children, title, size, description }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const toggleAccordion = () => {
		setIsOpen(!isOpen)
	}

	return (
		<DsAccordionContainer>
			<DsAccordionInternal onClick={toggleAccordion}>
				{size && size === 'sm' ? (
					<TextStyleBodyMedium>{title}</TextStyleBodyMedium>
				) : (
					<div>
						<DsAccordionTitle isOpen={isOpen}>{title}</DsAccordionTitle>
						{description && <TextStyleBodyDefault>{description}</TextStyleBodyDefault>}
					</div>
				)}
				<Tooltip title={isOpen ? 'Minimize' : 'Expand'}>
					<div>
						<DscIcons icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}></DscIcons>
					</div>
				</Tooltip>
			</DsAccordionInternal>
			{isOpen && <DsAccordionContent>{children}</DsAccordionContent>}
		</DsAccordionContainer>
	)
}

export default Accordion
