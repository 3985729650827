import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { VotesCommunity } from './PropostalDetails'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'

interface Props {
	votes: VotesCommunity[]
}

const VotesHighlight: React.FC<Props> = ({ votes }) => {
	// Count the number of Approve and Reject votes
	const approveVotes = votes.filter(vote => vote.voteResult === 'Approve').length
	const rejectVotes = votes.filter(vote => vote.voteResult === 'Reject').length
	const totalVotes = approveVotes + rejectVotes

	// Prepare data for the chart
	const data = [
		{ name: 'Approve', value: approveVotes, color: '#4caf50' }, // Green for approve
		{ name: 'Reject', value: rejectVotes, color: '#f44336' }, // Red for reject
	]

	const RADIAN = Math.PI / 180
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5
		const x = cx + radius * Math.cos(-midAngle * RADIAN)
		const y = cy + radius * Math.sin(-midAngle * RADIAN)

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={14}>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		)
	}

	return (
		<div className="bg-white rounded-[4px] p-2">
			<div className="min-h-[250px] h-[250px]">
				<ResponsiveContainer>
					<PieChart>
						<Pie
							data={data}
							dataKey="value"
							nameKey="name"
							cx="50%"
							cy="50%"
							outerRadius={100}
							fill="#8884d8"
							labelLine={false}
							label={renderCustomizedLabel}>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={entry.color} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
				</ResponsiveContainer>
			</div>
			<div>
				<div className="flex items-center gap-2 justify-center">
					<div className="flex items-center gap-2 justify-center">
						<TextStyleBodyDefault className="flex items-center gap-1">
							Approve: <div className="approve-block"></div>
						</TextStyleBodyDefault>
						<TextStyleBodyDefault className="flex items-center gap-1">
							Reject: <div className="reject-block"></div>
						</TextStyleBodyDefault>
					</div>
				</div>
				<TextStyleBodyDefault className="flex items-center justify-center">
					Summary: {votes.length | 0} Votes
				</TextStyleBodyDefault>
				<div className="flex items-center gap-2 justify-center">
					<TextStyleBodyDefault>Approve: {((approveVotes / totalVotes) * 100).toFixed(1) || 0}%</TextStyleBodyDefault>
					<TextStyleBodyDefault>Reject: {((rejectVotes / totalVotes) * 100).toFixed(1) || 0}%</TextStyleBodyDefault>
				</div>
			</div>
		</div>
	)
}

export default VotesHighlight
