import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import DscIcons from 'src/components/_Dsc/icons'
import Modal from 'src/components/_Dsc/Modal'
import Tabs from 'src/components/_Dsc/Tabs'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { User } from 'src/interface'
import { communityService } from 'src/service/community/communityService'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '' },
]

export interface Community {
	communityType: string
	createdAt: string
	proposalReason: string
	socialUserName: string
	createdBy: string
	description: string
	id: string
	members: string
	name: string
	status: string
	voteResult: string
	votesApprove: string
	votesReject: string
	votingPeriodEnd: string
	votingPeriodStart: string
	icon: string
}

const categories = [
	{
		label: 'Approve',
		value: 'Approve',
	},
	{
		label: 'Pending',
		value: 'Undecided',
	},
	{
		label: 'Reject',
		value: 'Reject',
	},
]

const Communities: React.FC = () => {
	const { user, setOnQueue, onQueue } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [inFetchUsers, setInFetchUsers] = useState<boolean>(false)
	const [userExistInCommunity, setuserExistInCommunity] = useState<boolean>(false)
	const [userPendingRequest, setUserPendingRequest] = useState<boolean>(false)
	const [communitites, setCommunities] = useState<Community[]>([])
	const [isSelected, setIsSelected] = useState('Approve')
	const [selectedCommunity, setSelectedComunity] = useState<Community>({} as Community)
	const [showModal, setShowModal] = useState<boolean>(false)

	const navigate = useNavigate()

	const getCommunitiesByType = async (type: string) => {
		setCommunities([])
		setInFetch(true)
		let req = await communityService.getCommunities(type)

		setCommunities(req)
		setInFetch(false)
	}

	const setFilterNotifications = (val: string) => {
		setCommunities([])
		setInFetch(true)
		setIsSelected(val)
		getCommunitiesByType(val)
	}

	const handleChangeModal = async (community: Community) => {
		setShowModal(true)
		setInFetchUsers(true)
		let req = await communityService.verifyUserExistInCommunity(user, community.id)
		let pendingVerify = await communityService.verifyPendingRequest(user, community.id)
		setuserExistInCommunity(req)
		setUserPendingRequest(pendingVerify)
		setInFetchUsers(false)
		setSelectedComunity(community)
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const applyUserForCommunity = async (community: Community) => {
		setOnQueue(true)
		await communityService.applyForCommunity(user, community)
		closeModal()
		setOnQueue(false)
	}

	React.useEffect(() => {
		getCommunitiesByType(isSelected)
	}, [])

	return (
		<div className="page-padding">
			<Modal
				open={showModal}
				title={'Apply for Membership'}
				onClose={closeModal}
				onCancel={closeModal}
				cancelLabel="Cancel">
				<div className="flex items-center justify-center">
					{inFetchUsers ? (
						<Loading />
					) : (
						<div>
							{userExistInCommunity ? (
								<TextStyleBodyDefault>You are already in this community</TextStyleBodyDefault>
							) : userPendingRequest ? (
								<TextStyleBodyDefault>
									You have already made the request to join the community, wait for approval
								</TextStyleBodyDefault>
							) : (
								<Button
									disabled={onQueue}
									value="Request access to the community"
									size="small"
									onClick={() => {
										applyUserForCommunity(selectedCommunity)
									}}
								/>
							)}
						</div>
					)}
				</div>
			</Modal>
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>Communities</TextStyleH1>
							{user && user.address ? (
								<Button
									value="Create Community proposal"
									size="small"
									onClick={() => {
										navigate('/community-proposal')
									}}
								/>
							) : (
								''
							)}
						</div>
						<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
					</div>
					{inFetch ? (
						<Loading />
					) : (
						<div className="mt-4 flex flex-col gap-2">
							{communitites && communitites.length ? (
								communitites.map((val: Community, index: number) => (
									<div
										key={index}
										className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px]">
										<div className=" flex  w-full justify-between">
											<div className="flex items-center gap-2">
												<img src={val.icon} width="60px" height="60px" className="rounded-full" />
												<div className="flex flex-col gap-1">
													<TextStyleH3>{val.name}</TextStyleH3>
													<TextStyleBodyDefault>{val.description}</TextStyleBodyDefault>
												</div>
											</div>
											<div className="flex flex-col items-end">
												<TextStyleCaption>{val.communityType}</TextStyleCaption>
												<div className="flex items-center gap-1">
													<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
													<DscIcons icon="group" />
												</div>
											</div>
										</div>
										{user && isSelected === 'Approve' && user.address !== val.createdBy ? (
											<div>
												<div className="flex items-center gap-2">
													<Button
														value="Request entry"
														size="small"
														onClick={() => {
															handleChangeModal(val)
														}}
													/>
													<Button
														value="View proposal"
														size="small"
														onClick={() => {
															navigate(`/community/proposal/${val.id}`)
														}}
													/>
												</div>
											</div>
										) : (
											<Button
												value="View proposal"
												size="small"
												onClick={() => {
													navigate(`/community/proposal/${val.id}`)
												}}
											/>
										)}
									</div>
								))
							) : (
								<p>No data to display</p>
							)}
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Communities
