import './App.scss'
import './globalStyle/index.scss'
import Router from './router'
import { KineProvider } from './context/KineProvider'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
function App() {
	return (
		<KineProvider>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</KineProvider>
	)
}

export default App
