function tokensToPlanks(tokens: number) {
	// O fator de conversão para Polkadot onde 1 token é 10^12 planks.
	const plankPerToken = BigInt(10 ** 12)

	// Convertendo tokens para planks.
	const planks = BigInt(tokens) * plankPerToken

	// Retornando como string para lidar com valores muito grandes.
	return planks.toString()
}

function planksToTokens(planksWithCommas: number | string) {
	// Convertendo para string se for um número
	const planksString = typeof planksWithCommas === 'number' ? planksWithCommas.toString() : planksWithCommas

	if (planksString) {
		// Removendo as vírgulas da string.
		const planks = planksString.replace(/,/g, '')
		const plankPerToken = BigInt(10 ** 12)

		const tokens = BigInt(planks) / plankPerToken
		return Number(tokens)
	} else {
		return 0
	}
}

export { tokensToPlanks, planksToTokens }
