import { web3FromAddress } from '@polkadot/extension-dapp'
import { Keyring } from '@polkadot/api'
import { AccountId } from '@polkadot/types/interfaces'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { SetStateAction } from 'react'
import React from 'react'
import { NotificationService } from '../notification/notificationService'
import API from '../api'
import { User } from 'src/interface'
import { IKineraPost } from 'src/pages/social/components/feed/interface'
import { Community } from 'src/pages/communities'

const getAllPosts = async (_apiState: any) => {
	try {
		let req = await API.get(`/api/social/`)
		return req.data.data
	} catch (err) {
		console.error(err)
	}
}

const popularProfiles = async () => {
	try {
		let req = await API.get(`/api/popular-profiles`)
		return req.data.data
	} catch (err) {
		console.error(err)
	}
}

const getAllSocialPosts = async () => {
	try {
		let req = await API.get(`/api/social/posts/festivals`)
		return req.data.data
	} catch (err) {
		console.error(err)
	}
}

const createPost = async (
	user: any,
	content: string,
	type: any,
	urls: string[],
	setGlobalToast: any,
	isFestival = false,
	categorie = '',
	id: number = 0,
	setGlobalSnack: (value: SetStateAction<any>) => void,
	community: Community | null = null,
) => {
	let payload: any = {
		address: user.address,
		content: content,
		type: type ? type : 'New Post',
		isFestival: isFestival,
		categorie: categorie,
		event_id: id,
		urls: urls,
	}
	if (community) {
		payload.is_community_post = true
		payload.community_id = community.id
	}
	try {
		setGlobalToast(true)
		let req = await API.post(`/api/social/`, payload)
		setGlobalToast(false)
		setGlobalSnack(true)
		return req.data
	} catch (err) {
		setGlobalToast(false)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
		})
	}
}

const commentOnPost = async (postId: string, comment: string, user: any, globalDispatch: any, target?: string) => {
	const payload = {
		post_id: postId,
		content: comment,
		address: user.address,
	}

	//console.log('target', target)
	try {
		globalDispatch(true)
		let req: any = await API.post(`/api/comment`, payload)
		if (req && req.data && target && target !== user.address) {
			await NotificationService.sendNotify(target, `A user commented on your post::${req.data.data.postId}`, 2, 0)
		}
		globalDispatch(false)
		return req.data
	} catch (er) {
		globalDispatch(false)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const removeComment = async (user: User, id: string, postId: string) => {
	const payload = {
		user: user.address,
		comment: id,
	}

	//console.log('target', target)
	try {
		let req: any = await API.post(`/api/comment/remove/${postId}`, payload)

		return req.data
	} catch (er) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const commentOnComment = async (postId: string, comment: string, user: any, globalDispatch: any, target?: string) => {
	const payload = {
		comment_id: postId,
		content: comment,
		address: user.address,
	}

	try {
		globalDispatch(true)
		let req: any = await API.post(`/api/comment/commentoncomment`, payload)
		if (req && req.data && target) {
			await NotificationService.sendNotify(target, `A user commented on your post::${req.data.data.postId}`, 2, 0)
		}
		globalDispatch(false)
		return req.data
	} catch (er) {
		globalDispatch(false)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {},
		})
	}
}

const getCommentsOnPost = async (id: string, apiState: any) => {
	try {
		let req = await API.get(`/api/comment/` + id)
		return req.data.data
	} catch (e) {
		console.log(e)
	}
}

const getCommentsAnswers = async (id: string, apiState: any) => {
	try {
		let req = await API.get(`/api/comment/answers/` + id)
		return req.data.data
	} catch (e) {
		console.log(e)
	}
}

const postLike = async (id: string, address: string) => {
	try {
		let payload = {
			user: address,
		}
		let req = await API.post(`/api/social/like/` + id, payload)
		return req.data
	} catch (e) {
		console.log(e)
	}
}

const dislike = async (id: string, address: string) => {
	try {
		let payload = {
			user: address,
		}
		let req = await API.post(`/api/social/dislike/` + id, payload)
		return req.data.data
	} catch (e) {
		console.log(e)
	}
}

const commentLike = async (id: string, address: string) => {
	try {
		let payload = {
			user: address,
		}
		let req = await API.post(`/api/comment/like/` + id, payload)
		return req.data
	} catch (e) {
		console.log(e)
	}
}

const commentDislike = async (id: string, address: string) => {
	try {
		let payload = {
			user: address,
		}
		let req = await API.post(`/api/comment/dislike/` + id, payload)
		return req.data.data
	} catch (e) {
		console.log(e)
	}
}

const createSocialProfile = async (user: any, name: string, description: any, url: string, apiState: any) => {
	var connect = await apiState
	const MySwal = withReactContent(Swal)
	try {
		const injector = await web3FromAddress(user.address)
		var send = connect.tx.socialSpaceModule.createSpace(name, description, url)
		await send.signAndSend(user.address, { signer: injector.signer })
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (er) {
		console.log('error', er)

		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const getSocialProfile = async (user: any, apiState: any) => {
	let arrObjectPosts: Array<object> = []
	var connect = await apiState
	await connect.query.socialSpaceModule.spaces.entries().then(async (res: any) => {
		await res.forEach((value: any) => {
			var currentValue = value[1].toHuman()
			arrObjectPosts.push(currentValue)
		})
	})
	return arrObjectPosts
}

const getSocialProfileByAddres = async (address: string) => {
	if (address) {
		try {
			let req = await API.get(`/api/users/ipfs/` + address)

			if (req && req.data) return req.data
			else return req
		} catch (err: any) {
			if (err && err.response) {
				return err.response.data
			} else {
				return err
			}
		}
	} else {
		return
	}
}

const getSocialPostsByAddres = async (id: string, apiState: any) => {
	var connect = await apiState
	let arrObjectPosts = []
	const comments = await connect.query.socialSpaceModule.comments.entries(id)
	const userPromises = comments.map(async (value: any) => {
		var currentValue: any = value[1].toHuman()

		const nameUser: any = await connect.query.socialSpaceModule.spaces(currentValue.owner).then((userAcc: any) => {
			return userAcc.toHuman()
		})

		return {
			content: currentValue.text,
			date: currentValue.date,
			dislikes: currentValue.dislikes,
			id: currentValue.id,
			likes: currentValue.likes,
			owner: currentValue.owner,
			parentCommentId: currentValue.parentCommentId,
			postId: currentValue.postId,
			name: nameUser.name,
			imageLink: nameUser.imageLink,
		}
	})

	arrObjectPosts = await Promise.all(userPromises)
	return arrObjectPosts
}

const followingSocialProfile = async (user: any, userProfile: any, apiState: any) => {
	var connect = await apiState
	const keyring = new Keyring({ type: 'sr25519' })
	const MySwal = withReactContent(Swal)
	const injector = await web3FromAddress(user.address)
	const addressed = userProfile.owner
	const accountId = keyring.decodeAddress(addressed) as AccountId
	var send = connect.tx.socialSpaceModule.followSpace(accountId)
	try {
		await send.signAndSend(user.address, { signer: injector.signer })
		MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i> Your order has entered the queue and is already being processed.</i>,
			icon: 'success',
		})
	} catch (e) {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'An error occurred, please try again later.',
			footer: '<a href="">We are working to ensure that these errors do not occur.</a>',
			willClose: () => {
				window.location.reload()
			},
		})
	}
}

const getSocialUsersPosts = async (id: string, apiState: any) => {
	var connect = await apiState
	try {
		const comments = await connect.query.socialSpaceModule.postsBySpace(id)
		return comments.toHuman()
	} catch (e) {
		console.log('e', e)
	}
}

const verifyFollowAsync = async (id: string, follow: string) => {
	try {
		let req = await API.get(`/api/follow/${id}/${follow}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const followAsync = async (id: string, follow: string) => {
	try {
		let req = await API.post(`/api/users/follow/${id}/${follow}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const unfollowAsync = async (id: string, follow: string) => {
	try {
		let req = await API.post(`/api/users/unfollow/${id}/${follow}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getPostsById = async (id: string) => {
	try {
		let req = await API.get(`/api/social/posts/${id}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getFollowers = async (id: string) => {
	try {
		let req = await API.get(`/api/social/followers/${id}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getFollowings = async (id: string) => {
	try {
		let req = await API.get(`/api/social/followings/${id}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const editPost = async (id: string, payload: { user: string; content: string }) => {
	try {
		let req = await API.post(`/api/social/post/${id}`, payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getPost = async (id: string) => {
	try {
		let req = await API.get(`/api/social/post/${id}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const savePost = async (postId: number, userAddress: string) => {
	let payload = {
		post_id: postId,
		user_id: userAddress,
	}
	try {
		let req = await API.post('/api/favorites', payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

export const repost = async (tweet: IKineraPost, repostMessage: string, userAddress: string) => {
	let payload = {
		address: userAddress,
		content: tweet.message,
		type: tweet.type ? tweet.type : 'New Post',
		owner_id: tweet.user_id,
		repost_message: repostMessage,
		post_id: tweet.id,
	}
	//console.log('tweet', payload)
	try {
		let req = await API.post('/api/social/repost', payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const removeSave = async (postId: number, userAddress: string) => {
	let payload = {
		post_id: postId,
		user_id: userAddress,
	}
	try {
		let req = await API.post('/api/favorites/remove', payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getUserSavePosts = async (userAddress: string) => {
	let payload = {
		user_id: userAddress,
	}
	try {
		let req = await API.get(`/api/favorites/${userAddress}`)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const removePost = async (id: string, payload: { user: string }) => {
	console.log('payload', payload)
	try {
		let req = await API.post(`/api/social/post/remove/${id}`, payload)
		return req.data
	} catch (err) {
		console.error(err)
	}
}

const getCommunityPosts = async (id: string) => {
	try {
		let payload = {
			id: id,
		}
		let req = await API.post(`/api/communities/web3/posts`, payload)
		return req.data.data
	} catch (e) {
		console.log(e)
	}
}

export {
	getAllPosts,
	createPost,
	commentOnPost,
	getCommentsOnPost,
	createSocialProfile,
	getSocialProfile,
	getSocialProfileByAddres,
	followingSocialProfile,
	getSocialPostsByAddres,
	getSocialUsersPosts,
	verifyFollowAsync,
	followAsync,
	unfollowAsync,
	getPostsById,
	getFollowers,
	getFollowings,
	editPost,
	removePost,
	removeSave,
	getPost,
	commentOnComment,
	getCommentsAnswers,
	postLike,
	commentLike,
	commentDislike,
	getAllSocialPosts,
	removeComment,
	dislike,
	popularProfiles,
	savePost,
	getUserSavePosts,
	getCommunityPosts,
}
