import React from 'react'
import { getProfileTokens, festivalReward, rankingRewards } from '../../service/profile/profileService'
import KineContext from '../../context/KineContext'
import { Loading } from '../../components/loading'
import TitleH1 from '../../components/_Dsc/typography/TitleH1'
import { planksToTokens } from 'src/service/substrate/formated'
import DailyReward from './components'
import { Link } from 'react-router-dom'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleButtonLarge, TextStyleH1 } from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'

const Earnings: React.FC = () => {
	const [userTokens, setUserTokens] = React.useState<any | undefined>(undefined)
	const [fetch, setFetch] = React.useState<boolean>(true)
	const [onSendAction, sendAction] = React.useState<boolean>(false)

	const [onSendActionFestival, sendActionFestival] = React.useState<boolean>(false)

	const { user, color, apiState, profile } = React.useContext(KineContext)
	const getUserTokens = async () => {
		if (user) {
			setFetch(true)
			try {
				let req: any = await getProfileTokens(user, apiState)
				setUserTokens(req)
				setFetch(false)
			} catch (e) {
				console.log(e)
			}
		}
	}

	const verifyUpdateToken = async () => {
		try {
			let req: any = await getProfileTokens(user, apiState)
			setUserTokens(req)
		} catch (e) {
			console.log(e)
		}
	}

	const rankingsReward = async () => {
		sendAction(true)
		await rankingRewards(user, apiState)
		sendAction(false)
		sendActionFestival(false)
	}
	const rewardTokensFestival = async () => {
		sendAction(true)
		await festivalReward(user, apiState)
		sendAction(false)
		sendActionFestival(false)
	}

	React.useEffect(() => {
		if (apiState) {
			if (user && user.address) getUserTokens()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, apiState])

	React.useEffect(() => {
		if (apiState) {
			const interval = setInterval(() => {
				verifyUpdateToken()
			}, 5000)

			return () => clearInterval(interval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Claim Tokens', link: '' },
	]
	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />

			<CardInner>
				<CardContainer>
					<TextStyleH1>Redeem your tokens</TextStyleH1>
					{user ? (
						<div className="flex flex-col rounded-[4px] p-4 bg-white">
							{fetch ? (
								<Loading />
							) : (
								<div className="w-full flex flex-col h-full">
									<TextStyleButtonLarge>
										Here you can track tokens earned through staking in ranking lists and participation in festivals
									</TextStyleButtonLarge>
									{profile ? (
										<DailyReward />
									) : (
										<p className="text-center flex items-center  mt-2 gap-2 ">
											<Link
												to="/register-profile"
												className={`bg-[${color.action}] p-1 text-white font-bold flex items-center justify-center`}>
												Create a profile
											</Link>{' '}
											so you can redeem daily rewards
										</p>
									)}

									<article>
										<div className="flex flex-col ml-10 gap-10">
											<div className="space-y-5 xl:col-span-3">
												<div className="flex flex-col items-start bg-white p-2 rounded-[4px]">
													<h2 className="text-2xl font-bold leading-8 tracking-tight">
														<TextStyleH1>Rewards from Rankings</TextStyleH1>
													</h2>

													<div className="text-base font-medium leading-6 flex flex-col gap-2">
														<TextStyleButtonLarge>
															Available for redemption:{' '}
															<b>
																{userTokens && userTokens.claimableTokensRanking
																	? planksToTokens(userTokens.claimableTokensRanking)
																	: 0}
															</b>
														</TextStyleButtonLarge>
														{userTokens && parseInt(userTokens.claimableTokensRanking) > 0 && (
															<>
																{onSendAction ? (
																	<Loading />
																) : (
																	<Button
																		onClick={() => {
																			rankingsReward()
																		}}
																		value="Collect tokens"></Button>
																)}
															</>
														)}
													</div>
												</div>

												<div className="flex flex-col items-start bg-white p-2 rounded-[4px]">
													<div>
														<h2 className="text-2xl font-bold leading-8 tracking-tight">
															<TextStyleH1>Rewards from Constellations</TextStyleH1>
														</h2>
													</div>

													<div className="text-base font-medium leading-6 w-full flex flex-col gap-2">
														<TextStyleButtonLarge className={`text-black font-lg text-xl`}>
															Available for redemption:{' '}
															<b>
																{userTokens && planksToTokens(userTokens.claimableTokensFestival) >= 1
																	? planksToTokens(userTokens.claimableTokensFestival)
																	: 0}
															</b>
														</TextStyleButtonLarge>
														{userTokens && planksToTokens(userTokens.claimableTokensFestival) >= 1 && (
															<div className="w-full items-center justify-end flex">
																{onSendActionFestival ? (
																	<Loading />
																) : (
																	<Button
																		value="Collect tokens"
																		onClick={() => {
																			rewardTokensFestival()
																			sendActionFestival(true)
																		}}></Button>
																)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</article>
									<div className="w-full flex flex-wrap"></div>
								</div>
							)}
						</div>
					) : (
						<h1 className="text-lg leading-7 text-gray-500 dark:text-gray-400">
							Connect your account to view yours rewards
						</h1>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Earnings
