import React from "react";
import Button from "src/components/_Dsc/Button";

const UserCommunityProposal: React.FC = () => {
    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center gap-2">
                <Button value="Remove member proposal [soon]" size="small" type="warning"/>
                <Button value="Credits [soon]" size="small" />
            </div>
        </div>
    )
}

export default UserCommunityProposal