import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import ViewFile from './viewFile'
import Logo from '../../../assets/icons.svg'
import { TextStyleBody, TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import { fetchImages, getAllUserFilesPrivate, getAllUserFilesPublic } from 'src/service/storage/userImages'
import Tabs from 'src/components/_Dsc/Tabs'

interface Props {
	optionUserAddress?: string
}

const options = [
	{
		label: 'Private files',
		value: 'private',
	},
	{
		label: 'Public files',
		value: 'public',
	},
]

const MyPhotos: React.FC<Props> = ({ optionUserAddress }) => {
	const { user } = React.useContext(KineContext)
	const [images, setImages] = useState([] as any)
	const [isFetch, setIfetch] = useState(true)
	const [modal, setModal] = useState(false)
	const [selectedImage, setSelectedImage] = useState({} as any)
	const [isSelected, setIsSelected] = useState('private')
	const serverAddress = 'https://ia-tool.kinera.network'

	const setFilterNotifications = (val: string) => {
		setImages([])
		val === 'private' ? getAllPrivateFiles() : getAllPublicFiles()
		setIsSelected(val)
	}

	const closeModal = () => {
		setModal(false)
	}

	const handleShowModal = (image: any) => {
		setModal(true)
		setSelectedImage(image)
	}

	const getAllPrivateFiles = async () => {
		setIfetch(true)
		let req = await getAllUserFilesPrivate(user.address)
		if (req && req.length) {
			setImages(req)
		}
		setIfetch(false)
	}

	const getAllPublicFiles = async () => {
		setIfetch(true)
		let req = await getAllUserFilesPublic(user.address)
		if (req && req.length) {
			setImages(req)
		}
		setIfetch(false)
	}

	useEffect(() => {
		setImages([])
		if (user) {
			isSelected === 'private' ? getAllPrivateFiles() : getAllPublicFiles()
		}
	}, [user, modal])

	return (
		<div className="w-full h-full  flex flex-col ">
			{modal && <ViewFile selectedImage={selectedImage} onClose={closeModal}></ViewFile>}
			{isFetch ? (
				<Loading />
			) : (
				<div>
					<div className="mb-2 w-fit mx-auto">
						<Tabs onChange={setFilterNotifications} options={options} value={isSelected}></Tabs>
					</div>
					{images && images.length ? (
						<div className="flex items-center gap-2 wrap flex-wrap">
							{images.map((val: any, key: number) => (
								<div
									key={key}
									className="relative grow basis-1/3 min-w-[calc(33.333%-1rem)] flex items-center justify-center group">
									<img src={val.url} className="image-card grow basis-1/3 w-full" />
									<div className="absolute post-image opacity-0 group-hover:opacity-100 transition-opacity duration-200">
										<Button
											value="View"
											onClick={() => {
												handleShowModal(val)
											}}
										/>
									</div>
								</div>
							))}
						</div>
					) : (
						'No image to display'
					)}
				</div>
			)}
		</div>
	)
}

export default MyPhotos
