import axios from 'axios'
import Cookies from 'js-cookie'
// Verifica se está rodando em localhost
const isLocal = window.location.host.includes('localhost')

// Cria uma instância de axios com a configuração baseURL
const API = axios.create({
	baseURL: isLocal ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL,
	headers: {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY, // Adicione esta chave se necessário
	},
})

API.interceptors.request.use(
	config => {
		// Busca os dados do usuário armazenados no cookie 'kinera-cookie'
		const userCookie = Cookies.get('kinera-cookie')

		if (userCookie) {
			try {
				const parsedUser = JSON.parse(userCookie)
				if (parsedUser && parsedUser.access_token) {
					config.headers.Authorization = `Bearer ${parsedUser.access_token}`
				}
				if (parsedUser && parsedUser.address) {
					config.headers.address = `${parsedUser.address}`
				}
			} catch (error) {
				console.error('Error parsing kinera-cookie:', error)
			}
		}

		return config
	},
	error => {
		return Promise.reject(error)
	},
)
/**
 * 
API.interceptors.response.use(
	response => {
		return response
	},
	error => {
		let userData = Cookies.get('kinera-cookie')
		if (userData) {
			Cookies.remove('kinera-cookie')
		}

		return Promise.reject(error)
	},
)
 */

export default API
