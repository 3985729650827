import React, { useContext, useEffect, useState } from 'react'
import Web3 from 'web3'
import Button from 'src/components/_Dsc/Button'
import { CardContainer } from 'src/components/_Dsc/Card'
import Modal from 'src/components/_Dsc/Modal'
import Tabs from 'src/components/_Dsc/Tabs'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import CreateNewOrder from './order'
import InputText from 'src/components/_Dsc/InputText'
import { Tooltip } from '@mui/material'
import DscIcons from 'src/components/_Dsc/icons'
import { ethers } from 'ethers'
import { JsonRpcProvider } from 'ethers'

const categories = [
	{ label: 'MATIC / Polygon', value: 'polygon' },
	{ label: 'USDT / Polygon', value: 'usdt' },
]

const MATCI_RECEIPEMENT_ADDREESS = '0x16733E2E1FBBc1f8F43E601Fcd5296B4f532683d'
const USDT_RECEIPEMENT_ADDRESS = '0x7C0e2938C3eaDb59E9Ac029Aee001c3b5e740C59'
const USDT_CONTRACT_ADDRESS = '0x802596e5C874b9BBE747dBCA4a0A5221d2165cfd'

const Storage: React.FC = () => {
	const { storageUsed, user } = useContext(KineContext)
	const [openModal, setOpenModal] = useState(false)
	const [confirmCreateOrder, setConfirmCreateOrder] = useState(false)
	const [amount, setAmount] = useState('0')
	const [selectedCategory, setSelectedCategory] = useState('polygon')
	const [accounts, setAccounts] = useState<string[]>([])
	const [selectedAccount, setSelectedAccount] = useState<string>('')

	useEffect(() => {
		const loadAccounts = async () => {
			if (window.ethereum) {
				const web3 = new Web3(window.ethereum)
				try {
					const accs: string[] = await web3.eth.requestAccounts()
					if (accs.length > 0) {
						setAccounts(accs)
						setSelectedAccount(accs[0])
					}
				} catch (error) {
					console.error('Failed to load accounts', error)
				}
			} else {
				alert('MetaMask or Trust Wallet not detected')
			}
		}
		loadAccounts()
	}, [])

	const handleAccountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedAccount(event.target.value)
	}

	const sendUSDT = async () => {
		if (window.ethereum && selectedAccount) {
			const web3 = new Web3(window.ethereum)

			if (selectedCategory === 'polygon') {
				const usdtContractAddress =
					selectedCategory === 'polygon' ? MATCI_RECEIPEMENT_ADDREESS : USDT_RECEIPEMENT_ADDRESS

				const usdtContract = new web3.eth.Contract(
					[
						{
							constant: false,
							inputs: [
								{ name: '_to', type: 'address' },
								{ name: '_value', type: 'uint256' },
							],
							name: 'transfer',
							outputs: [{ name: '', type: 'bool' }],
							type: 'function',
						},
					],
					selectedCategory === 'polygon' ? MATCI_RECEIPEMENT_ADDREESS : usdtContractAddress,
				)

				const value = web3.utils.toWei(selectedCategory === 'polygon' ? `${amount}000000000000` : '1', 'mwei')

				try {
					await usdtContract.methods.transfer(usdtContractAddress, value).send({
						from: selectedAccount,
						value: '0', // Ensure no MATIC is sent as native currency
					})

					alert('Transaction successful!')
				} catch (error) {
					console.error('Failed to send USDT', error)
					alert('Transaction failed.')
				}
			}

			if (selectedCategory === 'usdt') {
				if (typeof window.ethereum !== 'undefined') {
					const recipientAddress = '0x742d35Cc6634C0532925a3b844Bc454e4438f44e'

					const amount = ethers.parseUnits('1.0', 6)

					createTransaction(recipientAddress, amount.toString())
				}
			}
		}
	}

	const createTransaction = async (toAddress: string, amount: string) => {
		if (typeof window.ethereum !== 'undefined') {
			// Conectar ao provider do MetaMask
			const provider = new ethers.BrowserProvider(window.ethereum)
			await provider.send('eth_requestAccounts', [])

			const signer = await provider.getSigner()

			const usdtContractAddress = '0x3813e82e6f7098b9583FC0F33a962D02018B6803'

			const usdtAbi = ['function transfer(address to, uint amount) public returns (bool)']

			const usdtContract = new ethers.Contract(usdtContractAddress, usdtAbi, signer)

			try {
				const tx = await usdtContract.transfer(toAddress, amount)
				console.log('Transaction Hash:', tx.hash)
				await tx.wait() // Espera pela confirmação
				console.log('Transaction confirmed')
			} catch (err) {
				console.error('Transaction failed:', err)
			}
		} else {
			console.error('MetaMask is not installed or the user did not grant permission.')
		}
	}

	const setFilterNotifications = (val: string) => {
		setAmount('0')
		setConfirmCreateOrder(false)
		setSelectedCategory(val)
	}

	const closeModal = () => {
		setOpenModal(false)
		setConfirmCreateOrder(false)
	}

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const createOrder = () => {
		setConfirmCreateOrder(true)
	}

	const handleChangeAmount = (e: any) => {
		setAmount(e)
	}

	return (
		<CardContainer>
			{openModal && (
				<Modal
					onConfirm={sendUSDT}
					confirmLabel="Create order"
					cancelLabel="Cancel"
					title="Add funds"
					open={openModal}
					confirmDisabled={parseFloat(amount) < 1}
					onCancel={closeModal}
					onClose={closeModal}>
					<div className="flex flex-col gap-2">
						<Tabs onChange={setFilterNotifications} options={categories} value={selectedCategory} />
						<div className="flex flex-col gap-2">
							<h2>Select your wallet</h2>
							<select className="select-account" value={selectedAccount} onChange={handleAccountChange}>
								{accounts.map((account: any) => (
									<option key={account} value={account}>
										{account}
									</option>
								))}
							</select>

							<div className="flex flex-col gap-2 items-end">
								<InputText label="Amount" type="number" value={amount} onChange={handleChangeAmount} />
							</div>
						</div>
					</div>
				</Modal>
			)}
			{!storageUsed ? (
				<Loading />
			) : (
				<div className="w-full flex flex-col">
					{user && user.address ? (
						<div className="flex flex-col gap-2 font-mono w-full wrap flex-wrap">
							<div>
								<p>
									Current user: <b>{user.name}</b>
								</p>
								<p>
									Current Wallet: <b>{user.address}</b>
								</p>
							</div>
							<div className="w-full flex flex-col wrap flex-wrap gap-2">
								<div className="flex items-center gap-1">
									<p>
										Storage:{' '}
										<b>
											{storageUsed}/{user.storage || '2024'} KB
										</b>
									</p>
									<Button value="Buy more storage" size="small" disabled={!user.credits || user.credits <= 0} />
								</div>
								<div className="flex items-center gap-1">
									<Tooltip
										placement="top"
										title="USDT credits are used to use GPUs when generating images and videos with AI in Toll Productions or to buy more storage space">
										<div>
											<p className="flex gap-1">
												Credits: <b>{user.credits || 0}</b>
												<div className="-mt-1">
													<DscIcons icon="help" size="18" />
												</div>
											</p>
										</div>
									</Tooltip>
									<Button value="Add more credits" size="small" onClick={handleOpenModal} disabled />
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			)}
		</CardContainer>
	)
}

export default Storage
