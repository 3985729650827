import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleBodyLarge, TextStyleH1 } from 'src/components/_Dsc/typography'
import { communityService } from 'src/service/community/communityService'
import { Community } from '../communities'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import TimeUntilEnd from './components/Counter'
import Tabs from 'src/components/_Dsc/Tabs'
import ProposalDetails from './components/PropostalDetails'
import { getUserData } from 'src/service/profile/profileService'
import { UserProfile } from 'src/interface'
import ProposalVotes from './components/VotesProposal'

const path = [
	{ label: 'Communities', link: '/communities' },
	{ label: 'Community Proposal', link: '' },
]

const categories = [
	{
		label: 'Proposal',
		value: '1',
	},
	{
		label: 'Votes',
		value: '2',
	},
]

const CommunityProposal: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const { apiState } = React.useContext(KineContext)
	const [community, setCommunity] = useState<Community | null>(null) // Alterado para null
	const [inFetch, setInFetch] = useState<boolean>(true) // Inicia o carregamento como true
	const [block, setBlock] = useState<string>('')

	const [isSelected, setIsSelected] = useState('1')

	const getProposalDetails = async () => {
		let param = window.location.pathname
		setInFetch(true)
		try {
			if (id || param) {
				const communityReq = await communityService.getCommunity(id ? id : param)
				if (communityReq) {
					setCommunity(communityReq)
					currentBlock()
				}
			}
		} catch (error) {
			console.error('Erro ao buscar detalhes da proposta', error)
		}
	}

	const currentBlock = async () => {
		setInFetch(true)
		try {
			const req = await communityService.getCurrentAccBlock()
			setInFetch(false)
			if (req) {
				setBlock(req)
			}
		} catch (error) {
			console.error('Erro ao buscar o bloco atual', error)
		}
	}

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
	}

	useEffect(() => {
		getProposalDetails()
	}, [id])

	// Verifica se ainda está buscando ou se os dados já foram carregados
	if (inFetch) {
		return <Loading />
	}

	return (
		<div className="page-padding community-proposal">
			<Breadcrumb routes={path} />

			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex flex-col">
							<TextStyleH1>Community proposal</TextStyleH1>
							<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
						</div>
					</div>

					<div className="mt-4 rounded-[4px] gap-2 flex flex-col ">
						{community && community.name && block ? (
							isSelected === '1' ? (
								<ProposalDetails community={community} block={block} />
							) : (
								<ProposalVotes community={community}/>
							)
						) : (
							!inFetch && !community && <div>No data to display</div>
						)}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}
export default CommunityProposal
