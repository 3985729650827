import React, { useState } from 'react'
import SideBar from './Components/sidebar'

import './style.scss'
import SocialConfig from '../../components/config/SocialConfig'

import ProfileView from '../../components/config/ProfileView'
import { ModerationService } from '../../service/moderation/moderationService'
import KineContext from '../../context/KineContext'
import { Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ColorConfig } from './Components/ColorConfig'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import Storage from './Components/Storage'
import Tabs from 'src/components/_Dsc/Tabs'

const Config: React.FC = () => {
	const [selected, setSelected] = React.useState('storage')
	const [fetch, setFetch] = React.useState(false)
	/**
	 * const [showLanguages, setShowLanguages] = React.useState(false)
	 */
	const [haveProfile, setHaveProfile] = React.useState(false)
	const [isSelected, setIsSelected] = useState('storage')
	const { user, apiState, color } = React.useContext(KineContext)
	const categories = [
		{
			label: 'Storage',
			value: 'storage',
		},
		{
			label: 'Color Custom [soon]',
			value: 'cc',
		},
	]

	const getModerator = async () => {
		setFetch(false)
		await ModerationService.verifyModerator(user, apiState)

		setFetch(true)
	}

	const navigate = useNavigate()

	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Config', link: '' },
	]

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
	}

	React.useEffect(() => {
		if (!user || !user.address) {
			navigate('/')
		} else {
			getModerator()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, user])

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<TextStyleH1>User configuration</TextStyleH1>
			{fetch ? (
				<CardInner>
					<CardContainer className="bg-white">
						<div className="w-full  flex flex-col gap-2">
							<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
							{isSelected === 'storage' && <Storage />}
							{isSelected === 'cc' && <ColorConfig />}
						</div>
					</CardContainer>
				</CardInner>
			) : (
				<div className="flex  w-full  justify-center gap-[5px] ">
					<div className="w-[20%]">
						<Skeleton variant="rectangular" width={'100%'} height={'200px'} />
					</div>
					<div className="w-[80%]">
						<Skeleton variant="rectangular" width={'100%'} height={'200px'} />
					</div>
				</div>
			)}
		</div>
	)
}

export default Config

/**
 * {
  selected === 'profile' && <ProfileView />;
}
{
  selected === 'kinergy' && <KinergyConfig />;
}
{
  selected === 'social' && <SocialConfig />;
}

 */
