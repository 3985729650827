import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'
import DscIcons from 'src/components/_Dsc/icons'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import Tabs from 'src/components/_Dsc/Tabs'
import TweetBox from '../social/components/createTweet/TweetBox'
import { getCommunityPosts } from 'src/service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { Skeleton } from '@mui/material'
import Members from './components/Members'
import CommunityProposal from '../communityProposal'
import UserCommunityProposal from './components/Proposal'
import PendingApprovalModal from './components/PendingApprovalModal'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Community', link: '' },
]

const categories = [
	{
		label: 'Posts',
		value: 'Posts',
	},
	{
		label: 'Members',
		value: 'Members',
	},
	{
		label: 'Proposals',
		value: 'Proposals',
	},
]

const interactions = [1, 2, 3, 4, 5]

const CommunityDetails: React.FC = () => {
	const { user, profile } = React.useContext(KineContext)
	const navigate = useNavigate()
	const [isSelected, setIsSelected] = useState('Posts')
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [approvalModal, setApprovalModal] = useState(false)
	const [inFetchPosts, setInFetchPosts] = useState<boolean>(true)
	const [data, setData] = React.useState<any>([])
	const [hasUserPermission, setHasUserPermission] = useState<boolean>(false)
	const [communityDetails, setCommunityDetails] = useState<Community>({} as Community)
	
	const getCommunityDetails = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesById(window.location.pathname.split('/')[2])
		let verifyUserCommunity = false
		setInFetchPosts(true)
		if (user && user.address) {
			let reqByUser = await communityService.getCommunitiesByUser(user.address)
			if (req && req.id && reqByUser && reqByUser.length) {
				reqByUser.forEach((val: Community) => {
					if (val.id === req.id) {
						verifyUserCommunity = true
						communityPosts()
					}
				})
			} else {
				verifyUserCommunity = false
			}
		} else {
			verifyUserCommunity = false
		}
		//console.log('verifyUserCommunity', verifyUserCommunity)
		setHasUserPermission(verifyUserCommunity)
		setCommunityDetails(req)
		setInFetch(false)
	}

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
	}

	const communityPosts = async () => {
		let req = await getCommunityPosts(window.location.pathname.split('/')[2])
		setData(req)
		setInFetchPosts(false)
	}

	useEffect(() => {
		if (user && user.address) {
			getCommunityDetails()
		}
	}, [user])

	return (
		<div className="page-padding">
			<PendingApprovalModal community={communityDetails} open={approvalModal} setOpen={setApprovalModal}/>
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>
								{communityDetails && communityDetails.name ? communityDetails.name : 'Community'}{' '}
							</TextStyleH1>
							{
								!inFetch && hasUserPermission && user && communityDetails && communityDetails.createdBy === user.address
								? <Button value='Pending approval' size='small' onClick={() => {setApprovalModal(true)}}/>
								: ''
							}
						</div>
						{!inFetch ? (
							<>
								{hasUserPermission ? (
									<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
								) : (
									''
								)}
							</>
						) : (
							''
						)}
					</div>
					{hasUserPermission ? (
						<div className="flex flex-col gap-2 w-full rounded-[4px] mt-4">
							{inFetch ? (
								<div className="p-4">
									<Loading />
								</div>
							) : (
								isSelected === `Posts`
								?<div className="flex flex-col gap-2 p-4">
								{communityDetails && communityDetails.name ? (
									<div className="flex flex-col">
										<TweetBox getData={communityPosts} setData={setData} community={communityDetails}></TweetBox>
										<div className="mt-4">
											{!inFetchPosts ? (
												<Feed posts={data} profile={profile} setData={setData} />
											) : (
												interactions.map((value: any, index: number) => (
													<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
														<div className="flex items-center justify-between mb-2 w-full">
															<Skeleton variant="circular" width={60} height={60} />
															<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
														</div>
														<Skeleton variant="rectangular" width={'100%'} height={100} />
														<div className="mt-2 mb-2 w-full">
															<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
														</div>
													</div>
												))
											)}
										</div>
									</div>
								) : (
									<p>No data to display</p>
								)}
							</div>
								: isSelected === 'Members'
								? <Members />
								: <UserCommunityProposal />
							)}
						</div>
					) : !inFetch ? (
						<>
							{hasUserPermission ? (
								''
							) : (
								<TextStyleBodyDefault>You do not have permission to access this content</TextStyleBodyDefault>
							)}
						</>
					) : (
						<Loading />
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default CommunityDetails
