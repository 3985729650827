import React, { useEffect } from 'react'

import { FestivalService } from '../../service/festival/festivalService'
import './style.scss'

import Card from '../../components/card/Card'
import RankingCards from '../../components/tableList/TableList'
import { RankingService } from '../../service/ranking/index'
import { Loading } from '../../components/loading'
import { Skeleton } from '@mui/material'
import Dropdown from '../../components/dropdown'
import KineContext from '../../context/KineContext'
import { getAllPosts } from '../../service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { translate } from '../../context/Translate'

import { group1, group2, group3, group4 } from 'src/constants/rankings'


import DscBadge from 'src/components/_Dsc/badge'
import Accordion from 'src/components/_Dsc/Accordion'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Tabs from 'src/components/_Dsc/Tabs'

const defaultVal = [1, 2, 3, 4]
const categories = [
	{
		label: 'Posts',
		value: 'Posts',
	},
	{
		label: 'Constellations',
		value: 'Constellations',
	},
]

function Home() {
	const { apiState, profile, currentLangues } = React.useContext(KineContext)
	const [data, setData] = React.useState<any | null>(null)
	const [ranking, setRanking] = React.useState<any | null>(null)
	const [tags, setTags] = React.useState<any | null>(null)
	const [temp, setTemp] = React.useState<any | null>(null)
	const [showTemp, setShowTemp] = React.useState<any | null>(null)
	const [selected, setSelected] = React.useState<any | null>('All')
	const [accLabel, setAccLabel] = React.useState<any | null>('All')
	const [accCategory, setAccCategory] = React.useState<any | null>(null)
	const [fetch, setFetch] = React.useState<boolean | null>(false)
	const [dataPosts, setDataPosts] = React.useState<any>([])
	const [fetchPosts, setFetchPosts] = React.useState<boolean | null>(false)
	const [currentBlockNumber, setCurrentBlockNumber] = React.useState('')
	const [isSelected, setIsSelected] = React.useState('Posts')

	const changeCurrentTab = (tab: string) => {
		setIsSelected(tab)
	}

	const getAllData = async () => {
		try {
			setFetch(true)
			//const getApoloRankingsPromise = RankingService.getRankingsByApolo();
			const festivalsPromise = FestivalService.getFestivals(false, apiState)
			const rankingListsPromise = RankingService.getRankingLists(apiState)
			const Tags = FestivalService.getAllTags(apiState)
			const [festivals, rankingLists, tags] = await Promise.all([festivalsPromise, rankingListsPromise, Tags])

			setTags(tags)
			setRanking(rankingLists)
			setData(festivals)

			setFetch(false)
		} catch (error) {
			console.error(error)
		}
	}

	const updatedCategory = (category: string) => {
		if (category === 'All') {
			setAccCategory(tags)
			setTemp(data)
			setSelected(null)
			setAccLabel(category)
		} else {
			const acc = tags.filter((val: any) => val.key === category || val.param === category)
			console.log('acc', acc[0])
			console.log('accLabel', category)
			setAccLabel(category)
			setAccCategory(acc[0])
			return accCategory
		}
	}

	const selectedFilter = (filter: any) => {

		setSelected(filter)

		if (filter === 'Festival' || filter === 'Festivals' || filter.key === 'All') {
			setTemp(data)
			setShowTemp(false)
			return true
		} else {
			const filtered = data.filter((val: any) => {
				if (val.categoriesAndTags) {
					return val.categoriesAndTags.some((param: any) => param.includes(filter))
				}
				return false
			})
			console.log('asa', filtered)
			setTemp(filtered)
			setShowTemp(true)
			return filtered
		}
	}

	const getData = async () => {
		try {
			setFetchPosts(false)
			let posts = await getAllPosts(apiState)
			setDataPosts(posts)

			setFetchPosts(true)
		} catch (error) {
			console.error('Ocorreu um erro ao buscar os dados:', error)
		}
	}

	const currentBlock = async () => {
		let req = await FestivalService.getCurrentAccBlock(apiState)
		setCurrentBlockNumber(req)
	}

	useEffect(() => {
		if (apiState) {
			getAllData()
			getData()
			currentBlock()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="pb-[30px]">
			<div className="page-padding kinergy-home">
				{selected !== 'Ranking' && selected !== 'RankingList' && (
					<CardInner className="bg-white">
						<CardContainer>
							<div className="flex items-center gap-2">
								<TextStyleH1>{translate('CONSTELATIONS', currentLangues.value)}</TextStyleH1>
								{!fetch && (
									<div>
										<Dropdown props={tags} updatedCategory={updatedCategory} selected={accLabel} />
									</div>
								)}
							</div>
							{!fetch && (
								<div className="mt-4">
									{accLabel && accLabel !== 'All' && (
										<div className="flex items-center gap-3 tags-container min-h-[84px]">
											{accCategory &&
												accCategory.value &&
												accCategory.value.map((val: any) => (
													<DscBadge isSelected={val === selected}>
														<button
															onClick={e => {
																selectedFilter(val)
															}}>
															{val}
														</button>
													</DscBadge>
												))}
										</div>
									)}
								</div>
							)}
							{!fetch ? (
								<div className="flex items-center gap-2 grow flex-wrap">
									{showTemp
										? temp?.map(
												(value: any, i: number) =>
													i < 6 && (
														<div key={i}>
															<Card data={value} currentBlockNumber={currentBlockNumber} />
														</div>
													),
										  )
										: data &&
										  data.length > 0 &&
										  data?.map(
												(value: any, i: number) =>
													i < 8 && (
														<div key={i} className="flex items-center ">
															<Card data={value} currentBlockNumber={currentBlockNumber} />
														</div>
													),
										  )}
									{!data && <Loading />}
								</div>
							) : (
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
									{defaultVal?.map(
										(value: any, i: number) =>
											i < 6 && (
												<div key={i}>
													<Skeleton variant="rectangular" width={'100%'} height={200} />
												</div>
											),
									)}
								</div>
							)}
						</CardContainer>
					</CardInner>
				)}
				{selected !== 'Festival' && (
					<div className="mt-4 ">
						<CardInner className="bg-white">
							<CardContainer>
								{ranking ? (
									<div className="w-full flex flex-col h-full gap-[8px]">
										<Accordion
											title="Crypto Tube"
											description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals.">
											<RankingCards
												data={ranking}
												group={group1}
												title="Crypto Tube"
												remove="Crypto Tube"
												description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals."
											/>
										</Accordion>

										<Accordion
											title="Comedy Tube"
											description="Comedy is one of the most neglected genres and, at the same time, one of the most censored.">
											<RankingCards
												data={ranking}
												group={group3}
												title="Comedy Tube"
												remove="Comedy Tube"
												description="Comedy is one of the most neglected genres and, at the same time, one of the most censored."
											/>
										</Accordion>

										<Accordion
											title="Artificial Intelligence"
											description="Here, we list AI videos from entertainment to experimental art and advanced simulations.">
											<RankingCards
												data={ranking}
												group={group2}
												title="Artificial Intelligence"
												description="Here, we list AI videos from entertainment to experimental art and advanced simulations."
											/>
										</Accordion>

										<Accordion title="Cinema" description="Cinema rankings. Curation open to all.">
											<RankingCards
												data={ranking}
												group={group4}
												title="Cinema"
												remove="Cinema"
												description="Cinema rankings. Curation open to all."
											/>
										</Accordion>
									</div>
								) : (
									<Loading />
								)}
							</CardContainer>
						</CardInner>
					</div>
				)}
			</div>

			<CardContainer>
				<div className="flex items-center">
					<hr className="seperation-border mr-2 flex-grow " />
					<p className="m-0 -mt-[2px] p-0 text-center text-lightBlue font-bold">Social Network</p>
					<hr className="seperation-border ml-2 flex-grow " />
				</div>
				<div className='flex flex-col gap-2'>
				<div className="w-full flex items-center ">
					<Tabs options={categories} value={isSelected} onChange={changeCurrentTab}></Tabs>
				</div>
				{fetchPosts ? (
					<div className="flex flex-col w-full  mb-10">
						<Feed posts={dataPosts} profile={profile} />
					</div>
				) : (
					<div className="flex flex-col w-full  mb-10">
						<Loading />
					</div>
				)}
				</div>
			</CardContainer>
		</div>
	)
}

export default Home
